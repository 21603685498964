import { ActivityCode, ContentType, FreeFieldCode, InterestCode } from 'pleinchamp-api-client/dist/src/content/content';
import { Content, SearchType } from 'pleinchamp-api-client/dist/src/content/search';
import { Geopoint } from 'pleinchamp-api-client/dist/src/custom';
import { FreeFieldApi } from '@api/business/FreeFieldContent.api';
import {call15dForecasts, call36hForecasts, callSummaryForecasts} from '@meteo/business/Meteo.api';
import { getCustomJournalNews, searchContent } from '@search/business/search.utils';
import { getProductType } from '@stocks/business/Stocks.api';
import { NB_JOURNAL_EVENTS, NB_JOURNAL_NEWS_HEADLINES } from '@utils/api.utils';
import { Constants } from '@utils/Constants';
import { UserProductType } from 'pleinchamp-api-client/dist/src/productTypes/productTypes';

export function fetch36hForecasts(coord?: Geopoint) {
  if (!coord) {
    return Promise.resolve(undefined);
  }
  const { latitude, longitude } = coord;
  return call36hForecasts(latitude, longitude);
}

export function fetch15dForecasts(coord?: Geopoint) {
  if (!coord) {
    return Promise.resolve(undefined);
  }
  const { latitude, longitude } = coord;
  return call15dForecasts(latitude, longitude);
}

export function fetchSummaryForecasts(coord?: Geopoint) {
  if (!coord) {
    return Promise.resolve(undefined);
  }
  const { latitude, longitude } = coord;
  return callSummaryForecasts(latitude, longitude);
}

export async function fetchEvents(stateOrProvinceCode?: string): Promise<Content[]> {
  try {
    const data = await searchContent({
      contentTypes: [ContentType.Event],
      limit: NB_JOURNAL_EVENTS,
      searchType: SearchType.Prioritize,
      stateOrProvinceCodes: stateOrProvinceCode ? [stateOrProvinceCode] : undefined,
    });
    return data.contents;
  } catch (err) {
    console.error('Error while calling search for events :', err);
    return Constants.EMPTY_ARRAY;
  }
}

export async function fetchNewHeadlines() {
  const data = await searchContent({
    contentTypes: [ContentType.Article],
    isHighlighted: true,
    limit: NB_JOURNAL_NEWS_HEADLINES,
    searchType: SearchType.Prioritize,
  });
  return data.contents;
}

export async function fetchCustomNews(
  isAuthenticated: boolean,
  stateOrProvinceCode: string | undefined,
  interests: InterestCode[],
  activities: ActivityCode[],
  isPrefsSet: boolean
) {
  if (!isAuthenticated || !isPrefsSet) {
    return Promise.resolve(Constants.EMPTY_ARRAY as Content[]);
  }
  try {
    const data = await getCustomJournalNews({
      activities,
      interests,
      stateOrProvinceCodes: stateOrProvinceCode ? [stateOrProvinceCode] : undefined,
    });
    return data.contents;
  } catch (err) {
    console.error('Error while calling search for custom news :', err);
    return Constants.EMPTY_ARRAY as Content[];
  }
}

export function fetchFreeField() {
  return FreeFieldApi.fetchForCode(FreeFieldCode.Home);
}

export async function fetchProductTypes(
  isMobile: boolean,
  userProductTypeLoading: boolean,
  userProductTypeError?: number,
  userProductTypes?: UserProductType[]
) {
  if (userProductTypeLoading) {
    return Promise.resolve([]);
  }
  if (userProductTypeError) {
    return Promise.reject(userProductTypeError);
  }
  if (userProductTypes && userProductTypes.length > 0) {
    return Promise.resolve(userProductTypes.slice(0, isMobile ? 1 : 2));
  }

  const defaultProductTypesIds = Constants.JOURNAL_DEFAULT_STOCK_PRODUCT_TYPES_ID.slice(0, isMobile ? 1 : 2);
  const types = await Promise.all(defaultProductTypesIds.map(getProductType));
  return types ? (types.filter(t => t?.products && t?.products.length > 0) as UserProductType[]) : [];
}
