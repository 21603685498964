import { ContentType, SearchType } from 'pleinchamp-api-client';
import { MeteoApi } from '@api/business/api.utils';
import { searchContent } from '@search/business/search.utils';
import { Constants } from '@utils/Constants';

export async function call36hForecasts(...params: Parameters<typeof MeteoApi.get36hForecasts>) {
  const response = await MeteoApi.get36hForecasts(...params);
  return response.data;
}

export async function callHourlyForecasts(...params: Parameters<typeof MeteoApi.getHourlyForecasts>) {
  const response = await MeteoApi.getHourlyForecasts(...params);
  return response.data;
}

export async function call15dForecasts(...params: Parameters<typeof MeteoApi.get15dForecasts>) {
  const response = await MeteoApi.get15dForecasts(...params);
  return response.data;
}

export async function callWeeklyForecasts(...params: Parameters<typeof MeteoApi.getWeeklyForecasts>) {
  const response = await MeteoApi.getWeeklyForecasts(...params);
  return response.data;
}

export async function callSummaryForecasts(...params: Parameters<typeof MeteoApi.getSummaryForecasts>) {
  const response = await MeteoApi.getSummaryForecasts(...params);
  return response.data;
}

export async function callTreatmentScheduleForecasts(...params: Parameters<typeof MeteoApi.getTreatmentSchedule>) {
  const response = await MeteoApi.getTreatmentSchedule(...params);
  return response.data;
}

export function fetchMeteoNews() {
  return searchContent({
    contentTypes: [ContentType.Article],
    limit: Constants.FEATURED_NEWS_LIMIT,
    searchType: SearchType.Prioritize,
  }).then((data) => data.contents);
}